<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add New event
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addeventFormalidate">
        <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="event Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="event Name"
                rules="required"
              >
                <b-form-input
                data-testid="event-name"
                  v-model="addeventForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                data-testid="event-link"
                  v-model="addeventForm.link_id"
                  @input="removeSpace($event)"
                  @change="checkLink"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="!validLink"
                  class="text-danger"
                >Not Valid</small>
                event link after creation:{{
                  `https://lpcentre.com/events/${addeventForm.link_id}`
                }}
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Duration "
              label-for="blog-edit-event"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="duration Link"
              >
                <b-form-input
                data-testid="event-duration"
                  v-model="addeventForm.duration"
                  type="number"
                />

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="date"
              label-for="date"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <flat-pickr
                data-testid="event-date"
                  v-model="addeventForm.date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <label>Save Event As</label>

            <b-form-radio-group
              v-model="addeventForm.online"
              label="saveas"
              class="custom-control-warning mb-2"
            >
              <b-form-radio value="1">
                Online
              </b-form-radio>
              <b-form-radio value="0">
                Classical
              </b-form-radio>
              <!-- <b-form-radio value="Both">
                Both
              </b-form-radio> -->
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label>Is Free</label>

            <b-form-radio-group
              v-model="addeventForm.is_free"
              label="saveas"
              class="custom-control-warning mb-2"
            >
              <b-form-radio value="1">
                yes
              </b-form-radio>
              <b-form-radio value="0">
                no
              </b-form-radio>
              <!-- <b-form-radio value="Both">
                Both
              </b-form-radio> -->
            </b-form-radio-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Venue"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="venue"
                rules="required"
              >
                <b-form-input
                data-testid="event-venue"
                  v-model="addeventForm.venue"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Related Events"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                data-testid="event-related-events"
                  v-model="addeventForm.related_events"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="eventsList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related News"
              label-for="blog-edit-news"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                data-testid="event-related-news"
                  v-model="addeventForm.related_news"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="newsList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related Speakers"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                data-testid="event-related-speakers"
                  v-model="addeventForm.speakers_array"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="speakersList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related Organisers"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                data-testid="event-related-organizers"
                  v-model="addeventForm.orgnisers_array"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="organisersList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <label> Card Gradiant Color</label>
            <!-- <Vue2ColorPicker v-model="addeventForm.card_gradiant_color"></Vue2ColorPicker> -->
            <vue-color-picker
              v-model="addeventForm.card_gradiant_color"
              class="form-control"
            />
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Video Link"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
              >
                <b-form-input
                data-testid="event-video-link"
                  v-model="addeventForm.video_link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="brochure "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="brochure Link"
              >

                <b-form-file
                data-testid="event-brochure"
                  ref="refInputE2"
                  v-model="addeventForm.brochure"

                  placeholder="Choose file"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            addeventForm.tab_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-image-tab"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab_Image "
                        rules="required"
                      >
                        <b-form-file
                        data-testid="event-tab-image"
                          ref="refInputE2"
                          v-model="addeventForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addeventForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            addeventForm.header_image=''

                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-HEADER"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        rules="required"
                      >
                        <b-form-file
                        data-testid="event-header-image"
                          ref="refInputEl"
                          v-model="addeventForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addeventForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Og image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE3"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE3.src = Tabimage
                            addeventForm.tab_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                        data-testid="event-og-image"
                          ref="refInputE3"
                          v-model="addeventForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
              Mobile Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEM"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE3.src = Tabimage;
                      addeventForm.mobile_header_image = '';
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Mobile Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="mobile_header_image"
                      >
                        <b-form-file
                        data-testid="event-mobile-image"
                          ref="refInputEM"
                          v-model="addeventForm.mobile_header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab7"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addeventForm.alt_mobile_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Certificate image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEC"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refInputEC.src = Tabimage
                          addeventForm.certificate_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tab Image "
                    >
                      <b-form-file
                      data-testid="event-certificate-image"
                        ref="refInputEC"
                        v-model="addeventForm.certificate_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Certificate Tilte"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="certificate"
              >
                <b-form-input
                data-testid="event-certificate-title"
                  v-model="addeventForm.certificate_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Certificate"
              label-for="blog-content"
              class="mb-2"
              id="certificate-description"
            >
              <validation-provider
                #default="{ errors }"
                name="certificate"
              >
                <quill-editor
                  ref="certificateEditor"
                  v-model="addeventForm.certificate"
                  :options="getEditorOptions('certificateEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Seamless image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewES"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refPreviewES.src = Tabimage
                          addeventForm.seamless_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tab Image "
                    >
                      <b-form-file
                      data-testid="event-seamless-image"
                        ref="refInputES"
                        v-model="addeventForm.seamless_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab5"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Seamless Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="seamless"
              >
                <b-form-input
                data-testid="event-seamless-title"
                  v-model="addeventForm.seamless_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Seamless"
              label-for="blog-content"
              class="mb-2"
              id="seamless-description"
            >
              <validation-provider
                #default="{ errors }"
                name="seamless"
              >
               <quill-editor
                  ref="seamlessEditor"
                  v-model="addeventForm.seamless"
                  :options="getEditorOptions('seamlessEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Networking image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEN"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refInputEN.src = Tabimage
                          addeventForm.networking_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tab Image "
                    >
                      <b-form-file
                      data-testid="event-network-image"
                        ref="refInputEN"
                        v-model="addeventForm.networking_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab6"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>

          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Networking Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="networking"
              >
                <b-form-input
                data-testid="event-network-title"
                  v-model="addeventForm.networking_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Networking"
              label-for="blog-content"
              class="mb-2"
              id="network-description"
            >
              <validation-provider
                #default="{ errors }"
                name="networking"
              >
                <quill-editor
                  ref="networkingEditor"
                  v-model="addeventForm.networking"
                  :options="getEditorOptions('networkingEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Participate image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEP"
                    :src="Tabimage"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refPreviewEP.src = Tabimage
                          addeventForm.participate_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group

                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="participate_image"
                    >
                      <b-form-file
                      data-testid="event-participate-image"
                        ref="refInputEP"
                        v-model="addeventForm.participate_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>

          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Participate Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="participate_title"
              >
                <b-form-input
                data-testid="event-participate-title"
                  v-model="addeventForm.participate_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Participate"
              label-for="blog-content"
              class="mb-2"
              id="participate-description"
            >
              <validation-provider
                #default="{ errors }"
                name="participate"
              >
               <quill-editor
                  ref="participateEditor"
            v-model="addeventForm.participate"
                  :options="getEditorOptions('participateEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="About event"
              label-for="blog-content"
              class="mb-2"
              id="about-description"
            >
              <validation-provider
                #default="{ errors }"
                name="about"
              >
              <quill-editor
                  ref="aboutEditor"
                  v-model="addeventForm.about"
                  :options="getEditorOptions('aboutEditor')"
                />
                
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Attenders  "
              label-for="blog-content"
              class="mb-2"
              id="attender-description"
            >
              <validation-provider
                #default="{ errors }"
                name="attenders"
              >
              <quill-editor
                  ref="attendersEditor"
                  v-model="addeventForm.attenders"
                  :options="getEditorOptions('attendersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Speakers"
              label-for="blog-content"
              class="mb-2"
              id="speakers-description"
            >
              <validation-provider
                #default="{ errors }"
                name="speakers"
              >
               <quill-editor
                  ref="speakersEditor"
                  v-model="addeventForm.speakers"
                  :options="getEditorOptions('speakersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3> Event agenda</h3>
            <b-form-group
              label="Select Day"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="day"
                rules=""
              >
                <v-select
                data-testid="event-agenda-day"
                  v-model="addeventForm.day"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationList"
                  @input="getDayContent(addeventForm.day)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Agenda"
              label-for="blog-content"
              class="mb-2"
              id="agenda-description"
            >
              <validation-provider
                #default="{ errors }"
                name="agenda"
              >
               <quill-editor
                  ref="dayContentFormE"
                  v-model="dayContentForm"
                  :options="getEditorOptions('dayContentFormE')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Organisers"
              label-for="blog-content"
              class="mb-2"
              id="organizers-description"
            >
              <validation-provider
                #default="{ errors }"
                name="organisers"
              >
              <quill-editor
                  ref="organisersEditor"
                  v-model="addeventForm.organisers"
                  :options="getEditorOptions('organisersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="border rounded p-3">
              <b-row v-if="addeventForm.is_free=='1'">
                <b-col cols="4">
                  <b-form-group
                    label="Early bird price"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Early bird price "
                     
                    >
                      <b-form-input
                      
                        v-model="addeventForm.early_bird_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Regular Price "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Event regular price"
                     
                    >
                      <b-form-input
                      
                        v-model="addeventForm.regular_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="End Early bird date"
                    label-for="date"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="End early bird date"
                    
                    >
                      <flat-pickr
                      
                        v-model="addeventForm.end_early_bird_date"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="4">
                  <b-form-group
                    label="Early bird price"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Early bird price "
                      rules="required"
                    >
                      <b-form-input
                        data-testid="event-early-price"
                        v-model="addeventForm.early_bird_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Regular Price "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Event regular price"
                      rules="required"
                    >
                      <b-form-input
                        data-testid="event-regular-price"
                        v-model="addeventForm.regular_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="End Early bird date"
                    label-for="date"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="End early bird date"
                      rules="required"
                    >
                      <flat-pickr
                      data-testid="end-early-date"
                        v-model="addeventForm.end_early_bird_date"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12">
                <b-form-group
                  label="Fees"
                  label-for="blog-content"
                  class="mb-2"
                  id="fees-description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fees"
                  >
                    <quill-editor
                  ref="feesEditor"
                  v-model="addeventForm.fees"
                  :options="getEditorOptions('feesEditor')"
                />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Register"
              label-for="blog-content"
              class="mb-2"
                id="register-description"
            >
              <validation-provider
                #default="{ errors }"
                name="register"
              >
               <quill-editor
                  ref="registerEditor"
                  v-model="addeventForm.register"
                  :options="getEditorOptions('registerEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Meta Keywords"
              label-for="blog-content"
              class="mb-2"
            >
              <b-form-tags 
              data-testid="event-meta-keyword"
              v-model="addeventForm.meta_keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=" Meta Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Meta Title"
              >
                <b-form-input
                data-testid="event-meta-title"
                  v-model="addeventForm.meta_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Meta Description"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Meta Description"
              >
                <b-form-input
                data-testid="event-meta-description"
                  v-model="addeventForm.meta_description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="event-submit-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
          </b-col></b-col></b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,

  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive, watch,getCurrentInstance } from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import Vue2ColorPicker from 'vue2-color-picker'
import store from '@/store'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refInputE3 = ref(null)
    const refPreviewE3 = ref(null)
    const isLoading = ref(false)
    const refPreviewE2 = ref(null)

    const refInputEC = ref(null)
    const refPreviewEC = ref(null)

    const refInputES = ref(null)
    const refPreviewES = ref(null)

    const refInputEN = ref(null)
    const refPreviewEN = ref(null)

    const refInputEP = ref(null)
    const refPreviewEP = ref(null)

    const Tabimage = ref('media/svg/files/blank-image.svg')
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData).then(response => {
        validLink.value = response.data.valid
      })
    }

    const durationList = ref([])

    const addeventForm = reactive({
      duration: 0,
      online: 0,
      is_free:0,
      day: 1,
      link_id: '',
      tab_image: [],
      header_image: [],
      alt_tab_image: '',
      speakers_array: [],
      orgnisers_array: [],
      related_events: [],
      related_news: [],
      alt_header_image: '',
      name: '',
      meta_keywords: '',
      meta_description: '',
      meta_title: '',
      date: '',
      og_image: '',
      about: '',
      attenders: '',
      speakers: '',
      agenda: '',
      end_early_bird_date: '',
      early_bird_price: '',
      regular_price: '',
      organisers: '',
      certificate_title: '',
      certificate_image: '',
      seamless_title: '',
      seamless_image: '',
      networking_title: '',
      networking_image: '',
      participate_title: '',
      participate_image: '',
      certificate: '',
      register: '',
      seamless: '',
      networking: '',
      card_gradiant_color: '#D69900',
      color: '',
      participate: '',
      venue: '',
      video_link: '',
      brochure: '',
      fees: '',
      alt_mobile_header_image:'',
      mobile_header_image:''
    })
    const eventsList = ref([])
    const removeSpace = target => {
      addeventForm.link_id = target
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''"":=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const { inputImageRendererTab4 } = useInputImageRenderer(
      refInputEC,
      base64 => {
        refPreviewEC.value.src = base64
      },
    )
    const { inputImageRendererTab5 } = useInputImageRenderer(
      refInputES,
      base64 => {
        refPreviewES.value.src = base64
      },
    )
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputEN,
      base64 => {
        refPreviewEN.value.src = base64
      },
    )
    const { inputImageRendererTab2 } = useInputImageRenderer(
      refInputEP,
      base64 => {
        refPreviewEP.value.src = base64
      },
    )



    const refPreviewEM = ref(null)
    const refInputEM= ref(null)
    const { inputImageRendererTab7 } = useInputImageRenderer(
      refInputEM,
      base64 => {
        refPreviewEM.value.src = base64
      },
    )
    const addeventFormalidate = ref()
    const form = ref()
    const speakersList = ref([])
    const organisersList = ref([])
    const newsList = ref([])
    store.dispatch('events/Allevent').then(response => {
      eventsList.value = response.data
    })
    store.dispatch('events/AllNews').then(response => {
      newsList.value = response.data
    })
    store.dispatch('speakers/Allspeakers').then(response => {
      speakersList.value = response.data
    })

    store.dispatch('organisers/Allorganisers').then(response => {
      organisersList.value = response.data
    })
    const dayContent = ref([])
    const daycontentArray = ref([])
    const dayContentForm = ref('')
    watch(dayContentForm, (val, oldVal) => {
      if (!dayContentForm.value) {
        dayContentForm.value = ''
      }

      if (dayContentForm.value !== '') {
        daycontentArray.value[addeventForm.day - 1] = dayContentForm.value
      } else if (daycontentArray.value[addeventForm.day - 1]) {
        dayContentForm.value = daycontentArray.value[addeventForm.day - 1]
        // daycontentArray.value[addeventForm.day-1]= daycontentArray.value[addeventForm.day-1]
      }
    })

    watch(addeventForm, (val, oldVal) => {
      durationList.value = []

      for (let i = 1; i <= addeventForm.duration; i++) {
        durationList.value.push(i)
      }
    })

    const getDayContent = num => {
      //  if(dayContent.value[num-1]){
      if (dayContent.value) {
        if (dayContent.value[num - 1] !== null) {
          dayContentForm.value = daycontentArray.value[num - 1]
        } else {
          dayContentForm.value = ''
        }
      } else {
        dayContentForm.value = ''
      }

      // CKEDITOR.instances.day_content.setData(dayContentForm.value[parseInt(i)-1]);
    }

    const save = () => {
      addeventFormalidate.value.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('fees', addeventForm.fees)
          formData.append('link_id', addeventForm.link_id)
          formData.append('brochure', addeventForm.brochure)
          formData.append('video_link', addeventForm.video_link)
         
            
            if(addeventForm.related_events.length > 0)
          {
            formData.append('related_events',
            JSON.stringify(addeventForm.related_events))
          }

            if(addeventForm.related_news.length > 0)
          {
            formData.append('related_news',
            JSON.stringify(addeventForm.related_news))
          }
          formData.append('orgnisers_array',
            JSON.stringify(addeventForm.orgnisers_array))
          formData.append('speakers_array',
            JSON.stringify(addeventForm.speakers_array))
          formData.append('duration', addeventForm.duration)
          // formData.append("link_id", addeventForm.link_id);
          formData.append('online', addeventForm.online)
          formData.append('is_free', addeventForm.is_free)
          formData.append('tab_image', addeventForm.tab_image)
          formData.append('header_image', addeventForm.header_image)
          formData.append('alt_tab_image', addeventForm.alt_tab_image)

          formData.append('alt_header_image', addeventForm.alt_header_image)

          formData.append('name', addeventForm.name)
          formData.append('meta_keywords', addeventForm.meta_keywords)
          formData.append('meta_description', addeventForm.meta_description)
          formData.append('meta_title', addeventForm.meta_title)

          formData.append('certificate_title', addeventForm.certificate_title)
          formData.append('certificate_image', addeventForm.certificate_image)
          formData.append('seamless_title', addeventForm.seamless_title)
          formData.append('seamless_image', addeventForm.seamless_image)

          formData.append('networking_title', addeventForm.networking_title)
          formData.append('networking_image', addeventForm.networking_image)
          formData.append('participate_title', addeventForm.participate_title)
          formData.append('participate_image', addeventForm.participate_image)

          formData.append('card_gradiant_color', addeventForm.card_gradiant_color)

          formData.append('date', addeventForm.date)
          formData.append('og_image', addeventForm.og_image)
          formData.append('about', addeventForm.about)
          formData.append('attenders', addeventForm.attenders)

          formData.append('speakers', addeventForm.speakers)
          formData.append('agenda', JSON.stringify(daycontentArray.value))

          formData.append('organisers', addeventForm.organisers)
          formData.append('end_early_bird_date', addeventForm.end_early_bird_date)
          formData.append('early_bird_price', addeventForm.early_bird_price)
          formData.append('regular_price', addeventForm.regular_price)
          formData.append('certificate', addeventForm.certificate)
          formData.append('register', addeventForm.register)
          formData.append('seamless', addeventForm.seamless)
          formData.append('networking', addeventForm.networking)
          formData.append('mobile_header_image', addeventForm.mobile_header_image)
          formData.append('alt_mobile_header_image', addeventForm.alt_mobile_header_image)
          formData.append('participate', addeventForm.participate)
          formData.append('venue', addeventForm.venue)
          if (validLink.value == true) {
            isLoading.value = true
            store
              .dispatch('events/addevent', formData)
              .then(response => {
                addeventForm.related_events = []
                addeventForm.orgnisers_array = []
                addeventForm.speakers_array = []
                emit('add-event')
                form.value.reset()
                daycontentArray.value = []
                isLoading.value = false
                refPreviewEM.value.src = 'media/svg/files/blank-image.svg'
                refPreviewEl.value.src = 'media/svg/files/blank-image.svg'
                refPreviewE2.value.src = 'media/svg/files/blank-image.svg'

                refPreviewE3.value.src = 'media/svg/files/blank-image.svg'
                refInputEC.value.src = 'media/svg/files/blank-image.svg'

                refPreviewES.value.src = 'media/svg/files/blank-image.svg'
                refPreviewEN.value.src = 'media/svg/files/blank-image.svg'
                refPreviewEP.value.src = 'media/svg/files/blank-image.svg'
                Vue.swal({
                  title: 'Event Added ',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              }).catch(error => {
                isLoading.value = false
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          } else {
            Vue.swal({
              title: '',
              text: 'Please Enter Valid Link',
              icon: 'error',
              confirmButtonText: 'ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        }
      })
    }
    function UploadImage(
      blobInfo,
      success,
      failure,
      progress,
    ) {
      let xhr
      let formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(
          `Image upload failed due to a XHR Transport error. Code: ${xhr.status}`,
        )
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
     const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
      daycontentArray,
      dayContent,
      durationList,
      dayContentForm,
      getDayContent,
      UploadImage,
      eventsList,
      newsList,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      refInputE3,
      refPreviewE3,
      Tabimage,
      addeventForm,
      inputImageRenderer,
      inputImageRendererTab,
      speakersList,
      organisersList,
      inputImageRendererTab2,
      refInputEP,
      refPreviewEP,
      refInputEN,
      refPreviewEN,
      inputImageRendererTab5,
      refInputES,
      refPreviewES,
      inputImageRendererTab7,
      refPreviewEM,
      refInputEM,
      inputImageRendererTab4,
      refInputEC,
      refPreviewEC,
      inputImageRendererTab3,
      inputImageRendererTab6,
      addeventFormalidate,
      removeSpace,
      form,
          linkHandler,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
      validLink,
      checkLink,
      isLoading,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    vSelect,
    ValidationProvider,
    Vue2ColorPicker,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    Editor,
    flatPickr,
    BForm,
 quillEditor,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}

.border.rounded.p-2 {
    height: 295px;
}
</style>
